/* Media Queries */
$xsm: 414px;
$sm: 768px;
$md: 1024px;
$lm: 1440px;
$xlm: 1920px;
$xxlm: 2500px;

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* .ios {
  .content-wrapper{
    padding-top: 50px!important;
  }
  .back-button{
    padding-top: 40px!important;
  }
  .event{
    padding-top: 40px!important;
  }
} */
.lks-bg-blue{
  background-color: #3ACEB9;
}
.ion-page, .ion-content, .ion-tabs{
  animation: fadeIn 0.7s 1!important;
}
.ads-card-wrap{
  animation: fadeIn 1s 1!important;
}
@keyframes slideIn {
  from { transform: translateX(1000px) }
  to { transform: translateX(0) }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
/* android layout fix */
.md{
  .profile-page{
    ion-header{
      margin-top: 33px;
      margin-bottom: 36px;
    }
  }
  .detail-content{
    .wrapper-event-header{
      .back-button{
        margin-top: 23px;
      }
    }
  }
}
/* ios layout fix */
.ios{
  .lucky-modal, .game-modal, .cgu-modal {
    padding-top: 56px;
  }
  .earnings-page{
    ion-header{
      &.header-ios{
        margin-top: 33px;
      }
    }
    .lucky-modal{
      padding-top: 66px;
    }
  }
  .profile-page{
    ion-header{
      margin-top: 43px;
      margin-bottom: 36px;
    }
  }
  .detail-content{
    .wrapper-event-header{
      padding-top: 56px;
      .back-button{
        margin-top: 43px;
      }
    }
  }
}

ion-app {
  margin-bottom: 0;
  transition: margin-bottom 420ms;
}

.no-landscape{
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .rotate-image{
    width: 50%;
  }
}
$red: #DA261B;

.menu-lang-info{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    p{
        color:white;
        font-size: 15px;
    }
    svg{
        color: white;
        width: 19px;
    }
}

.menu-header{
    position: absolute;
    right: 20px;
    bottom: 50%;
    transform: translateY(-50%);
}

.white{
    p{
        color:white;
    }
    svg{
        color: white;
    }
}
.black{
    p{
        color:black;
    }
    svg{
        color: black;
    }
}

// modal 
.overlay{
    background-color: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}
.modal-lang{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: calc(100% - 56px);
    height: 334px;
    border-radius: 20px;
    background-color: white;
    padding: 30px;
    .title{
            font-size: 24px;
    }
    .modal-buttons{
        display : flex;
        gap: 20px;
        .lucky-button{
            width: 50%;
            height: 39px;
            background-color: $red;
            &.annulation{
                background-color: transparent;
                border: 2px solid rgba(0, 0, 0, 0.05);
                color: black;
            }
        }
    }
    .lang-list{
        width: 100%;
        margin-top: 20px;
        .list{
            list-style: none;
            .lang{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                border-radius: 20px;
                margin-bottom: 20px;
                border: 2px solid rgba(0, 0, 0, 0.2);
                .circle{
                    width: 24px;
                    height: 24px;
                    border-radius: 12px;
                    border: 2px solid rgba(0, 0, 0, 0.2);
                    svg{
                        display: none;
                    }
                }
                &.active{
                    border: 4px solid $red;
                    .circle{
                        border: none;
                        background-color: $red;  
                        display: flex;
                        justify-content: center;
                        align-items: center;  
                        svg{
                            display: block;
                            color:white;
                            width: 14px;
                        }             
                    }
                }
            }
        }
    }
}