/* Media Queries */
$xsm: 414px;
$sm: 768px;
$md: 1024px;
$lm: 1440px;
$xlm: 1920px;
$xxlm: 2500px;

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* .ios {
  .content-wrapper{
    padding-top: 50px!important;
  }
  .back-button{
    padding-top: 40px!important;
  }
  .event{
    padding-top: 40px!important;
  }
} */
.lks-bg-blue{
  background-color: #3ACEB9;
}
.ion-page, .ion-content, .ion-tabs{
  animation: fadeIn 0.7s 1!important;
}
.ads-card-wrap{
  animation: fadeIn 1s 1!important;
}
@keyframes slideIn {
  from { transform: translateX(1000px) }
  to { transform: translateX(0) }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
/* android layout fix */
.md{
  .profile-page{
    ion-header{
      margin-top: 33px;
      margin-bottom: 36px;
    }
  }
  .detail-content{
    .wrapper-event-header{
      .back-button{
        margin-top: 23px;
      }
    }
  }
}
/* ios layout fix */
.ios{
  .lucky-modal, .game-modal, .cgu-modal {
    padding-top: 56px;
  }
  .earnings-page{
    ion-header{
      &.header-ios{
        margin-top: 33px;
      }
    }
    .lucky-modal{
      padding-top: 66px;
    }
  }
  .profile-page{
    ion-header{
      margin-top: 43px;
      margin-bottom: 36px;
    }
  }
  .detail-content{
    .wrapper-event-header{
      padding-top: 56px;
      .back-button{
        margin-top: 43px;
      }
    }
  }
}

ion-app {
  margin-bottom: 0;
  transition: margin-bottom 420ms;
}

.no-landscape{
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .rotate-image{
    width: 50%;
  }
}
.button-close {
  position: absolute;
  top: 50px;
  left: 30px;
  width: 60px;
  height: 60px;

  --border-radius: 50%;

  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  --background: #fff;
  --background-activated: var(--ion-color-primary-shade, #fff);
  --background-focused: var(--ion-color-primary-shade, #fff);
  --background-hover: var(--ion-color-primary-tint, #fff);

  z-index: 100;

  ion-img {
    width: 24px;
    height: 24px;
  }
}
