:root {
  --black: #000;
  --white: #fff;
  --ion-color-quizz: #fff;
  // --ion-color-quizz-rgba: rgba(255, 255, 255, 0.8);
  --ion-color-quizz-contrast: #16328f;
  // --ion-color-quizz-contrast-rgb: 255, 255, 255;
  // --ion-color-quizz-shade: #5ca56c;
  // --ion-color-quizz-tint: #78c288;
  --red: #ea4343;
  --red-button: #da261b;
  --orange: #f45728;
  --green-profile: #3aceb9;
  --ion-font-family: "Inter", sans-serif;
}

.link {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  /* identical to box height */
  color: var(--black);
  letter-spacing: 0.1125px;
}

@font-face {
  font-family: "Clash Grotesk";
  src:
    url("~/assets/fonts/ClashGrotesk-Regular.woff2") format("woff2"),
    url("~/assets/fonts/ClashGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clash Grotesk";
  src:
    url("~/assets/fonts/ClashGrotesk-Medium.woff2") format("woff2"),
    url("~/assets/fonts/ClashGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clash Grotesk";
  src:
    url("~/assets/fonts/ClashGrotesk-Bold.woff2") format("woff2"),
    url("~/assets/fonts/ClashGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clash Grotesk";
  src:
    url("~/assets/fonts/ClashGrotesk-Extralight.woff2") format("woff2"),
    url("~/assets/fonts/ClashGrotesk-Extralight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clash Grotesk";
  src:
    url("~/assets/fonts/ClashGrotesk-Semibold.woff2") format("woff2"),
    url("~/assets/fonts/ClashGrotesk-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clash Grotesk";
  src:
    url("~/assets/fonts/ClashGrotesk-Light.woff2") format("woff2"),
    url("~/assets/fonts/ClashGrotesk-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
