#root {
    font-family: Inter, sans-serif;
    font-weight: 500;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-size: 14px;
}

.win-loose-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100vw;
    height: 100vh;
    background: rgb(0 0 0 / 55%);
    padding: 30px;
    z-index: 9999;
    .modal {
        position: relative;
        padding: 30px;
        background: var(--white);
        border-radius: 20px;
        box-shadow: 0 20px 25px rgb(7 104 30 / 20%);

        &::before {
            content: '';
            display: block;
            top: -6px;
            left: -6px;
            position: absolute;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            border: 1px solid #fff;
            border-radius: 25px;
            z-index: -1;
        }

        .team-img {
            display: block;
            width: 80px;
            height: 80px;
            margin: -60px auto 0;
        }

        .win-loose-img {
            width: auto;
            height: 34px;
            margin: 20px auto 30px;
        }

        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 40px;
            text-align: center;
        }

        ion-button {
            --background: #2c3949;

            color: var(--white);
            font-weight: 500;
            font-size: 21px;
            line-height: 26px;
            height: 66px;
            text-transform: uppercase;
        }
    }
}

.error-message {
    margin: 10px 0;
    color: var(--red);
    font-size: 12px;
}

.modal-centered {
    top: 50%;
    transform: translateY(-50%);
    text-align: start;
    font-size: 16px;
    width: calc(100% - 32px);
    margin: 0 auto;
    p.error-message {
        text-align: start;
        font-size: 16px;
    }
}
