/* Media Queries */
$xsm: 414px;
$sm: 768px;
$md: 1024px;
$lm: 1440px;
$xlm: 1920px;
$xxlm: 2500px;

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* .ios {
  .content-wrapper{
    padding-top: 50px!important;
  }
  .back-button{
    padding-top: 40px!important;
  }
  .event{
    padding-top: 40px!important;
  }
} */
.lks-bg-blue{
  background-color: #3ACEB9;
}
.ion-page, .ion-content, .ion-tabs{
  animation: fadeIn 0.7s 1!important;
}
.ads-card-wrap{
  animation: fadeIn 1s 1!important;
}
@keyframes slideIn {
  from { transform: translateX(1000px) }
  to { transform: translateX(0) }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
/* android layout fix */
.md{
  .profile-page{
    ion-header{
      margin-top: 33px;
      margin-bottom: 36px;
    }
  }
  .detail-content{
    .wrapper-event-header{
      .back-button{
        margin-top: 23px;
      }
    }
  }
}
/* ios layout fix */
.ios{
  .lucky-modal, .game-modal, .cgu-modal {
    padding-top: 56px;
  }
  .earnings-page{
    ion-header{
      &.header-ios{
        margin-top: 33px;
      }
    }
    .lucky-modal{
      padding-top: 66px;
    }
  }
  .profile-page{
    ion-header{
      margin-top: 43px;
      margin-bottom: 36px;
    }
  }
  .detail-content{
    .wrapper-event-header{
      padding-top: 56px;
      .back-button{
        margin-top: 43px;
      }
    }
  }
}

ion-app {
  margin-bottom: 0;
  transition: margin-bottom 420ms;
}

.no-landscape{
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .rotate-image{
    width: 50%;
  }
}
.game-chronometer {
  position: relative;
  width: 105px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .animation-background {
    position: absolute;
    top: -20px;
    left: 0;
    width: 105px;
    height: 105px;
    background: conic-gradient(var(--white), var(--ion-color-quizz-contrast));
    transition: all 0.1s linear;

    &.last-seconds {
      background: conic-gradient(var(--red), var(--ion-color-quizz-contrast)) !important;
    }
  }

  .chronometer-background {
    position: relative;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 27px;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: var(--white);
  }

  .chrono-icon {
    position: absolute;
    top: 8px;
    left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background: var(--white);
    border-radius: 50%;
    margin-right: 9px;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
  }
}
