/* Media Queries */
$xsm: 414px;
$sm: 768px;
$md: 1024px;
$lm: 1440px;
$xlm: 1920px;
$xxlm: 2500px;

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* .ios {
  .content-wrapper{
    padding-top: 50px!important;
  }
  .back-button{
    padding-top: 40px!important;
  }
  .event{
    padding-top: 40px!important;
  }
} */
.lks-bg-blue{
  background-color: #3ACEB9;
}
.ion-page, .ion-content, .ion-tabs{
  animation: fadeIn 0.7s 1!important;
}
.ads-card-wrap{
  animation: fadeIn 1s 1!important;
}
@keyframes slideIn {
  from { transform: translateX(1000px) }
  to { transform: translateX(0) }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
/* android layout fix */
.md{
  .profile-page{
    ion-header{
      margin-top: 33px;
      margin-bottom: 36px;
    }
  }
  .detail-content{
    .wrapper-event-header{
      .back-button{
        margin-top: 23px;
      }
    }
  }
}
/* ios layout fix */
.ios{
  .lucky-modal, .game-modal, .cgu-modal {
    padding-top: 56px;
  }
  .earnings-page{
    ion-header{
      &.header-ios{
        margin-top: 33px;
      }
    }
    .lucky-modal{
      padding-top: 66px;
    }
  }
  .profile-page{
    ion-header{
      margin-top: 43px;
      margin-bottom: 36px;
    }
  }
  .detail-content{
    .wrapper-event-header{
      padding-top: 56px;
      .back-button{
        margin-top: 43px;
      }
    }
  }
}

ion-app {
  margin-bottom: 0;
  transition: margin-bottom 420ms;
}

.no-landscape{
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .rotate-image{
    width: 50%;
  }
}
ion-modal#consent-modal {
  --width: fit-content;
  --min-width: 50%;
  --max-width: 95%;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5%;

    .modal-header {
      text-align: left;

      .modal-header-title {
        padding: 0px;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.0125em;
      }
    }
    .modal-content {
      text-align: justify;
      padding: 0;
      p {
        color: rgba(0, 0, 0, 0.6);
      }

      .modal-buttons {
        display: flex;
        justify-content: right;
        gap: 5px;
        margin-top: 20px;
      }
    }
  }
}
