body {
  font-family: Inter, sans-serif !important;

  #tarteaucitronRoot {
    z-index: 999;
    #tarteaucitronAlertBig {
      background-color: white;
    }
  }

  #tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
  #tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
    font: 15px verdana;
    color: #000;
  }

  tarteaucitronalertbig,
  #tarteaucitronAlertBig #tarteaucitronPersonalize,
  #tarteaucitronAlertBig #tarteaucitronPersonalize2,
  .tarteaucitronCTAButton,
  #tarteaucitron #tarteaucitronPrivacyUrl,
  #tarteaucitron #tarteaucitronPrivacyUrlDialog,
  #tarteaucitronRoot .tarteaucitronDeny,
  #tarteaucitronRoot .tarteaucitronAllow {
    // background: #008300;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
    line-height: 1.2;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 7px;
    border-radius: 40px;
    width: 50%;
  }

  #tarteaucitronRoot span#tarteaucitronDisclaimerAlert {
    margin: 10px 0 30px;
    display: block;
    text-align: center;
    font-size: 17px;
  }

  #tarteaucitronRoot .tarteaucitronDeny {
    color: #000;
    background: #fff;
    border: 1px solid rgb(235 235 235);
  }

  #tarteaucitronRoot .tarteaucitronAllow {
    color: #000;
    background: #da261b !important;
    border: 1px solid rgb(235 235 235);
  }

  #tarteaucitronRoot #tarteaucitronCloseAlert {
    color: #000;
    font-size: 12px !important;
    text-decoration: underline;
  }

  #tarteaucitronRoot .tarteaucitronCheck {
    display: none;
  }

  div#tarteaucitronAlertBig::before {
    content: "Paramétrage des cookies";
    color: #000;
    font-weight: 800;
    font-size: 24px;
  }

  #tarteaucitronRoot div#tarteaucitron {
    width: 90% !important;
    min-width: 285px !important;
    margin-left: 50% !important;
    transform: translateX(-50%);
    margin-top: 40px !important;
    max-block-size: calc(100vh - 80px) !important;
    box-shadow: 0 0 9000px #000;
    border-radius: 20px;
    overflow: hidden;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
    background: #fff;

    .tarteaucitronH1 {
      color: #000;
      font-weight: 800;
      font-size: 24px;
    }
  }

  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle,
  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
  #tarteaucitron #tarteaucitronInfo,
  #tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
    background: #fff;
    color: #000;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine {
    background: #fff;
  }

  #tarteaucitronRoot .tarteaucitronBorder ul #tarteaucitronServicesTitle_analytic .tarteaucitronTitle button {
    margin-bottom: unset !important;
    text-decoration: underline;
  }

  a.tarteaucitronSelfLink {
    display: none;
  }
}
